<template>
  <div class="container">
    <div class="row justify-content-center align-content-center">
      <div class="col-10" id="upload">
        <input id="input" type="file" @change="readFile" accept=".html"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UploadNew',
  methods: {
    // eslint-disable-next-line consistent-return
    readFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => this.$emit('give-file', e.target.result);
      reader.readAsText(file);
      // this.file = JSON.parse(e.target.result);
    },
  },
};
</script>

<style scoped>
#upload {
}
#input {
  content: 'Select some files';
  display: inline-block;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}
</style>
