<template>
  <div ref="bm" id="cont">
  </div>
</template>

<script>
import lottie from 'lottie-web/build/player/lottie';
/* eslint-disable */
export default {
  name: 'lotti',
  data() {
    return {
    };
  },
  props: {
   animationData: Object,
   loop: Boolean,
   autoplay: Boolean,
  },
   async mounted() {
     lottie.loadAnimation({
       container: this.$refs.bm,
       renderer: 'svg',
       loop: this.loop,
       autoplay: this.autoplay,
       animationData: this.animationData // an imported animation data json object
     });

  },
  methods: {
  },
};
</script>

<style scoped>

#cont {
  border: 2px dotted red;
  height: 100%;
  width: 100%;
}
</style>
