<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-8 padding5">
        <h1 class="colorfull">Wähle deine HTML-Datei aus</h1>
        <div class="col-12"><p class="miniText">
          <strong>Hinweis</strong>: Deine Daten werden nirgendwo hochgeladen.
          Sie werden ausschließlich bei dir im Browser verarbeitet.
        </p></div>
      </div>
      <div class="col-10" id="upload" v-if="uploaded === false">
        <UploadNew @give-file="onClickChild"></UploadNew>
      </div>
      <div class="col-10"><p class="reading" ref="status"></p></div>
      <div v-if="uploaded === true && verarbeitet === false" class="col-12 verarbeitungHinweis">
        <p class="reading colorfull center">deine Daten werden gerade verarbeitet ... <br>
          (Je nach Größe deines Datensatzes,
          kann dieser Vorgang einen Moment dauern.)</p></div>
      <div class="col-12">
        <button v-if="verarbeitet" class="btn" @click="go">weiter</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import * as tf from '@tensorflow/tfjs';
import dict from '@/assets/keras/dict.json';
import UploadNew from '@/components/UploadNew';
import lotti from '@/components/lotti';
import { REMOTE_MODEL_URL } from '../config/config';

export default {
  name: 'Upload',
  components: {
    UploadNew,
    lotti,
  },
  methods: {
    go() {
      this.$router.push({
        name: 'Reading',
        params: {
          dataVorhanden: 1,
          reflect: this.reflect,

          fakten: this.fakten,
          faktenDiagramm: this.faktenDiagramm,
          faktenDiagramm2: this.faktenDiagramm2,
          faktenMost5: this.faktenMost5,
          faktenSuche: this.faktenSuche,
          faktenSuche2: this.faktenSuche2,
          faktenSuche3: this.faktenSuche3,

          tagesablauf: this.tagesablauf,
          tagesablaufDiagramm: this.tagesablaufDiagramm,

          InteressenMost5: this.InteressenMost5,
          NahrungMost5: this.NahrungMost5,
          UnterhaltungMost5: this.UnterhaltungMost5,
          ReisenMost5: this.ReisenMost5,
          WissenMost5: this.WissenMost5,
          ShoppingMost5: this.ShoppingMost5,
          GesundheitMost5: this.GesundheitMost5,

          searches: this.searches,
        },
      });
    },
    onClickChild(value) {
      const upload = [];
      const docs = new DOMParser().parseFromString(value, 'text/html')
        .getElementsByClassName('content-cell');
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].getElementsByTagName('a').length === 1 && docs[i].getElementsByTagName('b').length < 1) {
          const toStr = docs[i].innerHTML;
          if (toStr.slice(0, 4) === 'Nach') {
            upload.push(docs[i].innerHTML);
          }
        }
      }
      this.readSearches(upload);
    },
    readSearches(data) {
      for (let i = 0; i < data.length; i += 1) {
        const abschnitt = data[i]; // Ganzer Abschnitt
        const date = abschnitt.split('<br>')[1].split(',')[0]; // Date
        const time = abschnitt.split('<br>')[1].split(',')[1]; // Time
        const title = abschnitt.substring(abschnitt.indexOf('">') + 2).split('</a>')[0];

        this.searches.push(title); // Wichtig für Headlines!
        /* eslint-disable */
          const newObj = {
            title: title, // Nach>
            kat: 0,
            year: parseInt(date.slice(6,10)),
            month:parseInt(date.slice(3,5)),
            day: parseInt(date.slice(0,2)),
            hour: parseInt(time.slice(1,3)),
            min: parseInt(time.slice(4,6)),
          };
          this.data.push(newObj);
        console.log(this.data[i].day + '. ' + this.data[i].year  + '. ' + this.data[i].month + '  ' +this.data[i].hour + ' : ' + this.data[i].min);
      }

      //console.log('I found ' + this.data.length + 'Google Searches! ');
      this.$refs.status.innerText = 'Ich habe von dir ' + this.data.length + ' Suchanfragen gefunden!';
      this.uploaded = true;
      this.predict(13, 6);
    },
    loadDict() {
      let indi = 0;
      for (let i in dict) {
        let obj = {
          word: i,
          index: indi,
        };
        this.dict.push(obj);
        indi += 1;
      }
      // console.log('found Dictionarry with length of: ' + this.dict.length);
    },
    toNum(satz, len, dicto) {

      let liste = [];
      let txt = satz.split(' ');
      while (txt.length < len) {
        txt.push('padd0');
      }
      for (let j = 0; j < len; j++) {
        if (dicto.find(x => x.word === txt[j].toLowerCase())) {
          liste.push(dicto.findIndex(x => x.word === txt[j].toLowerCase()));
        } else {
          liste.push(1);
        }
      }
      return liste;
    },

    // Kategorisierung für alle Suchanfragen
    async predict(classes, maxlen) {
      const model = await tf.loadLayersModel(REMOTE_MODEL_URL);

      let percentI = 1;
      let percent1 = Math.round(this.data.length / 100);
      for (let i = 0; i < this.data.length; i++) {
        if (i === percent1 * percentI) {
          //this.$refs.status.innerText = percentI + ' %';
          console.log(percentI + ' %');
          percentI += 1;
        }
        let anfrage = this.data[i].title;
        let leng = 0;
        if (anfrage.split(' ').length >= maxlen) {
          leng = 5;
        } else {
          leng = anfrage.split(' ').length;
        }
        anfrage = this.toNum(anfrage, maxlen, this.dict);
        let prediction = model.predict(tf.tensor(anfrage));
        prediction = prediction.slice([0], [leng]);
        let index = Array.from(prediction.argMax(-1)
          .dataSync());
        // Add predictetd Class to data Objects
        this.data[i].kat = this.getClass(classes, index, prediction);
        //console.log(this.data[i].kat);
      }
      this.findOut();
    },

    // Looks for best matching class with original values and frequent
    getClass(classes, indexes, prediction) {
      const map = new Map();
      let bestMatch = [];
      for (let i = 0; i < indexes.length; i++) {

        let val = Array.from(prediction.dataSync())[i * classes + indexes[i]];

        // Predictions mit niedriger Wahrscheinlichkeit werden weniger berücksichtigt
        if (val > 0.2) {
          val = val * 1.2;
        } else {
          val = val * 0.8;
        }

        if (map.has(indexes[i])) {
          let old = map.get(indexes[i]);

          map.set(indexes[i], old + val); //(Array.from(prediction.dataSync())[i * classes + indexes[i]]));
        } else {

          map.set(indexes[i], val); //Array.from(prediction.dataSync())[i * classes + indexes[i]]);
        }

        //console.log(indexes);
      }
      const mapSort = new Map([...map.entries()].sort((a, b) => a[1] - b[1]));
      bestMatch = Array.from(mapSort.keys());

      return bestMatch.at(-1);
    },

    findOut() {
      // Basic Infos about searches
      this.fakten.push(this.data.length);
      let anfang = new Date(this.data[this.data.length - 1].month + '.' + this.data[this.data.length - 1].day + '.' + this.data[this.data.length - 1].year);
      let ende = new Date(this.data[0].month + '.' + this.data[0].day + '.' + this.data[0].year);
      this.fakten.push(this.days[anfang.getDay()] + ' ' + anfang.getDate() + '. ' + this.months[anfang.getMonth() + 1] + '. ' + anfang.getFullYear());
      this.fakten.push(this.days[ende.getDay()] + ' ' + ende.getDate() + '. ' + this.months[ende.getMonth() + 1] + '. ' + ende.getFullYear());
      let Difference_In_Time = ende.getTime() - anfang.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      let durchschnittTag = Math.round(this.data.length / Difference_In_Days);
      let durchschnittMonat = durchschnittTag * 30;
      this.fakten.push(durchschnittTag);
      this.fakten.push(durchschnittMonat);

      let perMonth = this.sortByMonths(anfang, ende);
      let anfragenMonat = perMonth[2]; // Liste mit Anzahl der Suchanfragen pro Monaten in Zahlen
      this.faktenDiagramm = perMonth[0]; // Liste mit Anzahl der Suchanfragen pro Monaten in %
      this.faktenDiagramm2 = perMonth[1]; // Liste mit den Monaten

      this.faktenMost5 = this.mostWords(3);
      let alleAnfragenFakten = this.getSearchesfor(this.searches, this.faktenMost5);
      this.faktenSuche = alleAnfragenFakten[0];
      this.faktenSuche2 = alleAnfragenFakten[1];
      this.faktenSuche3 = alleAnfragenFakten[2];

      // Tagesablauf konstruieren
      let hoursDay = this.sortByHoures(); // Map mit Suchanfragen für jede Stunde (1-24)
      this.tagesablaufDiagramm = hoursDay[1];
      this.tagesablauf.push(hoursDay[0]); // Uhrzeit mit meisten Anfragen
      //console.log(this.tagesablaufDiagramm);
      //console.log(hoursDay);

      let nightTime = [];
      let dayTime = [];
      for (let i = 0; i < 24; i++) {
        if (hoursDay[1][i] > 10) {
          dayTime.push(i + 1);
        } else {
          nightTime.push(i + 1);
        }
      }
      this.tagesablauf.push(24- dayTime.length); // Stunden Schlaf pro Nacht

      if(dayTime.includes(1)) { // Differenziehrung 2er Zyklen für einfachere Berrechnung

        this.tagesablauf.push(nightTime[0] - 1); // Anfang Schlafzeit
        this.tagesablauf.push(nightTime[nightTime.length - 1]); // Ende Schlafzeit
      } else {
        this.tagesablauf.push(dayTime[dayTime.length - 1]); // Anfang Schlafzeit
        this.tagesablauf.push(dayTime[0]); // Ende Schlafzeit
      }

      let KategorienTag = this.sortByKatAndTime(13);

      // To make sure that the categories from trained net match the normal categories
      for (let i = 0; i < KategorienTag.length; i++) {
        if (KategorienTag[i] == 0 || KategorienTag[i] == 1) {
          this.tagesablauf.push(0);
        }
        if (KategorienTag[i] == 2) {
          this.tagesablauf.push(1);
        }
        if (KategorienTag[i] == 3) {
          this.tagesablauf.push(2);
        }
        if (KategorienTag[i] == 4) {
          this.tagesablauf.push(3);
        }
        if (KategorienTag[i] == 5 || KategorienTag[i] == 6 || KategorienTag[i] == 7 || KategorienTag[i] == 10 || KategorienTag[i] == 11 || KategorienTag[i] == 12) {
          this.tagesablauf.push(4);
        }
        if (KategorienTag[i] == 8) {
          this.tagesablauf.push(5);
        }
        if (KategorienTag[i] == 9) {
          this.tagesablauf.push(6);
        }
      }
      this.tagesablauf.push(KategorienTag[0]);
      this.tagesablauf.push(KategorienTag[1]);

      // evtl. Interessen

      // Spitze des Eisbergs
      this.InteressenMost5 = this.mostWordsByKat4(7, 10, 11, 12, 50);
      this.NahrungMost5 = this.mostWordsByKat(6, 50);
      this.UnterhaltungMost5 = this.mostWordsByKat(5, 50);
      this.ReisenMost5 = this.mostWordsByKat(8, 50);
      this.WissenMost5 = this.mostWordsByKat2(0, 1, 50);
      this.ShoppingMost5 = this.mostWordsByKat(3, 50);
      this.GesundheitMost5 = this.mostWordsByKat(9, 3);

      // Phase 2: Reflektion
      this.reflect = this.sortByKat(13);

      // setze verrbeitet auf True damit Button zur nächsten Seite erscheinen kann
      this.verarbeitet = true;
    },

    sortByKat(anzahlkat) {

      let liste = [];
      let liste2 = [];
      for (let i = 0; i < anzahlkat; i++) {
        let howoften = 0;

        for (let j = 0; j < this.data.length; j++) {
          if (this.data[j].kat === i) {
            howoften += 1;
          }
        }
        liste.push(howoften);
      }
      // Unterkategorien zusammenfügen 1+2 und 8+11+12+13
      let liste7 = [liste[0] + liste[1], liste[2], liste[3], liste[4], liste[5] + liste[6] + liste[7] + liste[10] + liste[11] + liste[12], liste[8], liste[9]];

      for (let i in liste7) {
        liste2.push(Math.round(liste7[i] / this.data.length * 100));
      }
      return liste2;
    },

    sortByKatAndTime(anzahlkat) {
      let liste2 = [];
      for (let t = 1; t < 25; t++) {
        let map = new Map();
        for (let i = 0; i < anzahlkat; i++) {
          let howoften = 0;

          for (let j = 0; j < this.data.length; j++) {
            if (this.data[j].kat === i && Math.round(this.data[j].hour + this.data[j].min / 60) === t) {
              howoften += 1;
            }
          }
          map.set(i, howoften);
        }
        const mapSort = new Map([...map.entries()].sort((a, b) => a[1] - b[1]));
        const best = Array.from(mapSort.keys());
        liste2.push(best[best.length - 1]);
      }

      let nachts = this.getMostFrequent(liste2.slice(0, 4));
      let tags = this.getMostFrequent(liste2.slice(7, 22));
      return [tags, nachts];
    },

    sortByHoures() {

      let map = new Map();
      for (let i = 1; i < 25; i++) {
        let howoften = 0;

        for (let j = 0; j < this.data.length; j++) {
          if (Math.round(this.data[j].hour + this.data[j].min / 60) === i) {
            howoften += 1;
          }
        }
        map.set(i, howoften);
      }
      let hours = Array.from(map.values());
      const mapSort = new Map([...map.entries()].sort((a, b) => a[1] - b[1]));
      let zeitMost = Array.from(mapSort.keys());
      let max = Array.from(mapSort.values());
      let maxPeak = max[max.length - 1];

      let hoursPercent = [];

      for (let i = 0; i < 24; i++) {
        hoursPercent.push(Math.round(hours[i] / maxPeak * 100));
      }
      //console.log(hoursPercent);
      return [zeitMost[zeitMost.length - 1], hoursPercent, map];

    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    sortByMonths(anfang, ende) {

      let startY = anfang.getFullYear();
      let endY = ende.getFullYear();

      let diffYears = endY - startY + 1;

      let anzahlMonths = this.monthDiff(anfang, ende) + 1;

      let years = []; // anzahl aller Jahre in den Suchanfragen
      for (let i = 0; i < diffYears; i++) {
        years.push(startY + i);
      }
      let mapProzentual = new Map();
      let mapPerMonth = new Map();
      let index = 0;
      let gefunden = false;

      for (let j = 0; j < years.length; j++) {

        for (let m = 1; m < 13; m++) {
          let howoften = 0;
          for (let i = 0; i < this.data.length; i++) {

            if (this.data[i].month === m && this.data[i].year === years[j]) {

              howoften += 1;
              gefunden = true;
            }
          }

          if (gefunden && index < anzahlMonths) {
            //mapAbweichung.set(this.months[m] + '. ' + years[j], howoften - avMonths);
            mapPerMonth.set(this.months[m] + '. ' + years[j], howoften);
            index += 1;
          }
        }
      }
      const mapSort = new Map([...mapPerMonth.entries()].sort((a, b) => a[1] - b[1]));
      let max = Array.from(mapSort.values());
      let indexes = Array.from(mapPerMonth.keys());

      for (let i = 0; i < indexes.length; i++) {
        let old = mapPerMonth.get(indexes[i]);
        mapProzentual.set(indexes[i], Math.round(old / max[max.length - 1] * 100));
      }

      return [Array.from(mapProzentual.values()), Array.from(mapPerMonth.keys()), Array.from(mapPerMonth.values())];

    },

    getMostFrequent(arr) {
      const hashmap = arr.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
      return Object.keys(hashmap)
        .reduce((a, b) => hashmap[a] > hashmap[b] ? a : b);
    },

    mostWords(amountWords) {

      let badwords = ['', 'viel', 'hat', 'so', 'wer', '*', 'über', 'die', 'padd0', 'wo', 'der', 'das', 'von', 'nach', 'nicht', 'bis', 'man', 'am', 'mit', 'für', 'ist', 'was', 'ich', 'in', 'und', 'wie', '?', 'bei', 'im', 'wenn'];
      let worterbuch = new Map();
      for (let j = 0; j < this.data.length; j++) {
        let txt = this.data[j].title.toLowerCase()
          .split(' ');
        for (let i = 0; i < txt.length; i++) {
          if (worterbuch.has(txt[i])) {
            let old = worterbuch.get(txt[i]);
            worterbuch.set(txt[i], old += 1);
          } else {
            if (badwords.includes(txt[i])) {

            } else {
              worterbuch.set(txt[i], 1);
            }
          }
        }
      }
      const mapSort1 = new Map([...worterbuch.entries()].sort((a, b) => b[1] - a[1]));
      let best = Array.from(mapSort1.keys());
      return best.slice(0, amountWords);
    },

    mostWordsByKat(kat, amountWords) {

      let worterbuch = new Map();
      for (let j = 0; j < this.data.length; j++) {
        if (this.data[j].kat === kat) {
          let txt = this.data[j].title.toLowerCase()
            .split(' ');
          for (let i = 0; i < txt.length; i++) {
            if (worterbuch.has(txt[i])) {
              let old = worterbuch.get(txt[i]);
              worterbuch.set(txt[i], old += 1);
            } else {
              if (this.badwords.includes(txt[i])) {

              } else {
                worterbuch.set(txt[i], 1);
              }
            }
          }
        }
      }
      const mapSort1 = new Map([...worterbuch.entries()].sort((a, b) => b[1] - a[1]));
      let best = Array.from(mapSort1.keys());
      // console.log(best);
      return best.slice(0, amountWords);
    },

    mostWordsByKat2(kat, kat2, amountWords) {

      let worterbuch = new Map();
      for (let j = 0; j < this.data.length; j++) {
        if (this.data[j].kat === kat || this.data[j].kat === kat2) {
          let txt = this.data[j].title.toLowerCase()
            .split(' ');
          for (let i = 0; i < txt.length; i++) {
            if (worterbuch.has(txt[i])) {
              let old = worterbuch.get(txt[i]);
              worterbuch.set(txt[i], old += 1);
            } else {
              if (this.badwords.includes(txt[i])) {

              } else {
                worterbuch.set(txt[i], 1);
              }
            }
          }
        }
      }
      const mapSort1 = new Map([...worterbuch.entries()].sort((a, b) => b[1] - a[1]));
      let best = Array.from(mapSort1.keys());
      // console.log(best);
      return best.slice(0, amountWords);
    },

    mostWordsByKat4(kat, kat2, kat3, kat4, amountWords) {

      let worterbuch = new Map();
      for (let j = 0; j < this.data.length; j++) {
        if (this.data[j].kat === kat || this.data[j].kat === kat2 || this.data[j].kat === kat3 || this.data[j].kat === kat4) {
          let txt = this.data[j].title.toLowerCase()
            .split(' ');
          for (let i = 0; i < txt.length; i++) {
            if (worterbuch.has(txt[i])) {
              let old = worterbuch.get(txt[i]);
              worterbuch.set(txt[i], old += 1);
            } else {
              if (this.badwords.includes(txt[i])) {

              } else {
                worterbuch.set(txt[i], 1);
              }
            }
          }
        }
      }
      const mapSort1 = new Map([...worterbuch.entries()].sort((a, b) => b[1] - a[1]));
      let best = Array.from(mapSort1.keys());
      // console.log(best);
      return best.slice(0, amountWords);
    },

    getSearchesfor(array, schlagworter) {

      array = this.shuffle(array);

      let liste2 = [];
      for (let i = 0; i < schlagworter.length; i++) {

        let liste = [];
        let index = 0;
        let treffer = [];
        while (treffer.length < 100 && index < array.length) {//for (let j = 0; j < array.length; j++) {  // treffer.length < 5 ||
          // console.log(index);
          let words = array[index].toLowerCase()
            .split(' ');
          if (words.includes(schlagworter[i].toLowerCase())) {
            if (array[index].length > 30) {
              treffer.push(array[index].slice(0, 27) + '...');
            } else {
              treffer.push(array[index]);
            }
          }
          index += 1;
        }
        for (let t in treffer) {
          liste.push(treffer[t]);
        }
        liste2.push(liste);
      }
      //console.log(liste)
      return liste2;
    },

    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },

  },
  data() {
    return {
      data: [], // eingelesene Suchanfragen mit Datum und Kategorie
      uploaded: false,
      verarbeitet: false,
      dict: [], // Wörterbuch aus Python importiert
      searches: [], // Headlines for animation

      fakten: [],
      faktenDiagramm: [],
      faktenDiagramm2: [],
      faktenMost5: [],
      faktenSuche: [],
      faktenSuche2: [],
      faktenSuche3: [],

      tagesablauf: [],
      tagesablaufDiagramm: [],

      InteressenMost5: [],
      NahrungMost5: [],
      UnterhaltungMost5: [],
      ReisenMost5: [],
      WissenMost5: [],
      ShoppingMost5: [],
      GesundheitMost5: [],
      reflect: [],

      months: ['', 'Jan', 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
      days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      badwords: ['wir', 'wird', 'an', 'mich', 'ab', 'the', 'zum', 'muss', 'warum', 'bekommt', 'bekomme', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.', '', 'viel', 'hat', 'so', 'wer', '*', 'über', 'die', 'padd0', 'wo', 'der', 'das', 'von', 'nach', 'nicht', 'bis', 'man', 'am', 'mit', 'für', 'ist', 'was', 'ich', 'in', 'und', 'wie', '?', 'bei', 'im', 'wenn'],

    };
  },
  created() {
    this.loadDict();
  },
};
</script>

<style scoped>

#upload {
  border: 1px solid white;
  padding: 10vh 5vh;
  border-radius: 10px;

}

.col-12 {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.btn {
  color: white;
  border: 3px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}

a {
  color: white;
  text-decoration: none;
}

a:active, a:hover {
  color: white;
  text-decoration: none;
}

.padding5 {
  padding: 5vh;
}
.verarbeitungHinweis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}

</style>
